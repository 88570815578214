<template>
  <b-dropdown class="dropdown-select-class" size="sm" :class="{'home-select': home}" ref="dropdown-select-class" menu-class="menu-class scrollbar mt-0" no-caret :toggle-class="home ? 'text-left home-custom-caret home-custom-select text-truncate w-100' : 'text-left custom-caret custom-select text-truncate w-100'" variant="transparent">
    <template #button-content>
      {{itemActive ? itemActive.name : placeholder}}
    </template>
    <b-dropdown-text v-if="all" @click="setItemActive({ name: 'Tất cả', id: '' })" :class="{'active': '' === selectedId}">Tất cả</b-dropdown-text>
    <b-dropdown-text v-for="item in listData" :key="item.id" @click="setItemActive(item)" :class="{'active': item.id === selectedId}">{{item.name}}</b-dropdown-text>
  </b-dropdown>
</template>

<script>
import { mapState } from 'vuex'
import Resource from '~/common/api/resource'
const classResource = new Resource('category/class')
export default {
  props: {
    all: {
      type: Boolean,
      default: true,
      required: false
    },
    require: {
      type: Boolean,
      default: false,
      required: false
    },
    placeholder: {
      type: String,
      default () {
        return 'Chọn lớp'
      }
    },
    items: {
      type: Array,
      default () {
        return []
      }
    },
    selectedId: {
      type: String,
      default () {
        return ''
      }
    },
    home: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      listData: [],
      itemActive: null
    }
  },
  computed: {
    ...mapState([
      'storeRedirect'
    ])
  },
  watch: {
    selectedId () {
      this.itemActive = this.listData.find(it => it.id === this.selectedId)
      if (!this.itemActive) {
        this.itemActive = { name: 'Tất cả', id: '' }
      }
    }
  },
  async created () {
    await this.loadData()
    this.itemActive = this.listData.find(it => it.id === this.selectedId)
    this.setStore(this.selectedId)
  },
  methods: {
    setItemActive (item) {
      this.$refs['dropdown-select-class'].hide(true)
      this.itemActive = item
      this.$emit('findClass', item?.id || '', item)
      this.setStore(item?.id)
    },
    setStore (id) {
      const data = { ...this.$store.state.storeRedirect, class_id: id }
      this.$store.dispatch('setStoreRedirect', data)
    },
    async loadData () {
      if (this.items.length) {
        this.listData = this.items
        return
      }
      const params = {}
      this.listData = await classResource.list(params)
        .then(res => res.data)
    }
  }
}
</script>
