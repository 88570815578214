<template>
  <div class="user-info" v-if="user">
    <div class="avatar m-auto">
      <el-image :src="user.avatar || NoAvatar" alt="avatar" class="w-100 h-100 rounded-circle"></el-image>
    </div>
    <div class="user-name text-center text-capitalize">{{user.fullName}}</div>
<!--    <div class="row pb-3 pb-xl-1">-->
<!--      <div class="col-6 col-lg-6">-->
<!--        <div class="block-data text-center">-->
<!--          <div class="title">Level:</div>-->
<!--          <div class="value">{{level}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-6 col-lg-6">-->
<!--        <div class="block-data text-center point">-->
<!--          <div class="title">Điểm:</div>-->
<!--          <div class="value">{{score}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="user-number d-flex align-items-center"><img src="~/mlearn/icons/home/number-question.svg" alt="number-question" />Số câu đã hỏi:<span class="question ml-1">{{question.total_question}}</span></div>
    <div class="user-number d-flex align-items-center"><img src="~/mlearn/icons/home/number-answer.svg" alt="number-answer" />Số câu đã trả lời: <span class="answer ml-1">{{question.total_answer}}</span></div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'
import Resource from '~/common/api/resource'
const getInfoUser = new Resource('qa/statistic-by-user')
const getScoreUser = new Resource('score-management/score-info')

export default {
  data () {
    return {
      typeOfLevel: '',
      question: {
        total_answer: 0,
        total_question: 0
      },
      level: 0,
      score: 0,
      NoAvatar
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  mounted () {
    this.getInfo()
    this.getScore()
  },
  methods: {
    getInfo () {
      if (!this.user) { return }
      const query = {
        user_id: this.user.uuid
      }
      getInfoUser.list(query)
        .then((response) => {
          this.question = response.data
        })
    },
    getScore () {
      if (!this.user) { return }
      const query = {
        userId: this.user.uuid,
        pointTypeCode: 'DEFAULT'
      }
      getScoreUser.list(query)
        .then((response) => {
          const data = response.data
          if (data.levelName) {
            if (data.levelName.slice(0, 5).toLowerCase().includes('level')) {
              this.typeOfLevel = 'level'
              this.level = data.levelName.slice(6)
            } else if (data.levelName.slice(0, 4).toLowerCase().includes('hạng')) {
              this.typeOfLevel = 'hạng'
              this.level = data.levelName.slice(5)
              if (this.level === 'gia nhập') {
                this.level = 1
              }
            } else {
              this.level = data.levelName
            }
          }
          this.score = data.currentPoint
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="scss" scoped>
  .user-info{
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
    border-radius: 8px;
    padding:25px 16px 20px;
    @media (max-width:576px) {
      padding:15px 10px 15px;
    }
    @media (max-width:992px) {}
    @media (max-width:1366px) {
      padding:20px 15px 15px;
    }
    .avatar{
      border: 2px solid var(--primary);
      border-radius: 50%;
      width:80px;
      height:80px;
      @media (max-width:576px) {
        width:72px;
        height:72px;
      }
      @media (max-width:992px) {}
      @media (max-width:1366px) {}
      img{
        border-radius: 50%;
        width:80px;
        height:80px;
        @media (max-width:576px) {
          width:62px;
          height:62px;
        }
        @media (max-width:992px) {}
        @media (max-width:1366px) {
          width:74px;
          height:74px;
        }
      }
    }
    .user-name{
      font-weight: bold;
      font-size: 16px;
      padding-top: 10px;
      margin-bottom: 15px;
      @media (max-width:576px) {
        font-size: 14px;
        padding-top: 8px;
        margin-bottom: 10px;
      }
      @media (max-width:992px) {}
      @media (max-width:1366px) {}
    }
    .block-data{
      background-color: rgba(#005FAF, .1);
      padding:18px 0 14px;
      border-radius: 12px;
      @media (max-width:576px) {
        padding:10px 0;
      }
      @media (max-width:992px) {}
      @media (max-width:1366px) {}
      &.point{
        background-color: rgba(#FF2E17, .1);
        .title{
          color: var(--danger);
        }
        .value{
          color: var(--danger);
        }
      }
      .title{
        font-weight: 500;
        font-size: 14px;
        color: var(--primary);
      }
      .value{
        font-weight: bold;
        color: var(--primary);
        font-size: 33px;
        @media (max-width:576px) {
          font-size: 20px;
        }
        @media (max-width:992px) {}
        @media (max-width:1366px) {
          font-size: 18px;
        }
      }
    }
    .user-number{
      font-weight: 500;
      font-size: 14px;
      color: #868789;
      padding-top: 16px;
      @media (max-width:576px) {
        padding-top: 5px;
      }
      @media (max-width:992px) {}
      @media (max-width:1366px) {
        padding-top: 5px;
      }
      .question{
        color: var(--danger);
        font-size: 20px;
      }
      .answer{
        color: var(--primary);
        font-size: 20px;
      }
      img{
        margin-right: 8px;
        @media (max-width:576px) {
          margin-right: 7px;
        }
        @media (max-width:992px) {}
        @media (max-width:1366px) {}
      }
    }
  }
</style>
